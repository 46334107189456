import { Flex, Stack, Text } from '@mantine/core'

export function Main() {
  return (
    <Flex direction="column" align={'center'} flex={1}>
      <Stack align={'center'} my={'auto'} w={'65%'} gap={'sm'}>
        <Text>Main</Text>
      </Stack>
    </Flex>
  )
}

import { Group } from '@mantine/core'
import { useNavigate } from 'react-router-dom'

import {
  IconDiamond,
  IconTrophy,
  IconAffiliate,
  IconRosette
} from '@tabler/icons-react'

import { useHapticFeedback } from '@telegram-apps/sdk-react'

const ICON_SIZE = 40

export function BottomMenu() {
  const haptic = useHapticFeedback()
  const navigate = useNavigate()

  return (
    <Group
      align={'flex-end'}
      mt={'auto'}
      mb={'sm'}
      justify={'space-evenly'}
      onClick={() => haptic.impactOccurred('soft')}
    >
      <IconDiamond size={ICON_SIZE} onClick={() => navigate('/')} />
      <IconRosette size={ICON_SIZE} onClick={() => navigate('/')} />
      <IconAffiliate size={ICON_SIZE} onClick={() => navigate('/')} />
      <IconTrophy size={ICON_SIZE} onClick={() => navigate('/')} />
    </Group>
  )
}

import { create } from 'zustand'
import { TCharacter } from '@/types.ts'
import { api } from '@/api.ts'

interface AppState {
  character: TCharacter | null
  reward: object | null
  getCharacter: (initDataRaw: string, ref: string) => Promise<void>
  getLoot: () => Promise<void>
  setCharacter: (character: TCharacter) => void
}

const serverUrl = import.meta.env.VITE_SERVER_URL || ''

const useAppStore = create<AppState>((set) => ({
  character: null,
  reward: null,

  getCharacter: async (initDataRaw: string, ref: string) => {
    try {
      const url =
        serverUrl + `/api/init?${initDataRaw}` + (ref ? `&ref=${ref}` : '')
      const response = await fetch(url)
      if (response.ok) {
        const data: { character: TCharacter; token: string } =
          await response.json()
        set({ character: data.character })
        localStorage.setItem('auth_token', data.token)
      }
    } catch (e) {
      console.error(e)
    }
  },

  setCharacter: (character: TCharacter) => set({ character }),

  getLoot: async () => {
    const response = await api.get('/api/loot')
    if (response?.data) {
      console.log(response.loot)
      set({ character: response.data, reward: response.loot })
    }
  }
}))

const selectCharacter = (state: AppState) => state.character

export { useAppStore, selectCharacter }

import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import { TonConnectUIProvider } from '@tonconnect/ui-react'
import '@mantine/core/styles.css'
import { MantineProvider } from '@mantine/core'
import { SDKProvider } from '@telegram-apps/sdk-react'
import { IntlProvider } from 'react-intl'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './mockEnv.ts'
import './index.css'
import { Root } from '@/components/Root.tsx'
import ru from '../lang/ru.json'
import { Main } from '@/components/Main.tsx'

Sentry.init({
  dsn: 'https://33249f2efe5d0c83df425a9012663918@o4507623931576320.ingest.de.sentry.io/4507623933345872',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  tracesSampleRate: 0.1,
  tracePropagationTargets: ['localhost', /^https:\/\/api\.prcvl\.online\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        index: true,
        element: <Main />
      }
    ]
  }
])

ReactDOM.createRoot(document.getElementById('root')!).render(
  <TonConnectUIProvider
    manifestUrl={'https://s3.prcvl.online/tonconnect-manifest.json'}
    actionsConfiguration={{
      twaReturnUrl: 'https://husky-primary-collie.ngrok-free.app'
    }}
  >
    <IntlProvider messages={ru} locale={'ru'} defaultLocale={'ru'}>
      <MantineProvider
        theme={{
          fontFamily: 'Rubik Mono One',
          headings: { fontFamily: 'Rubik Mono One' }
        }}
      >
        <SDKProvider acceptCustomStyles debug>
          <RouterProvider router={router} />
        </SDKProvider>
      </MantineProvider>
    </IntlProvider>
  </TonConnectUIProvider>
)

import { Outlet } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Flex } from '@mantine/core'
import { retrieveLaunchParams, initMiniApp } from '@telegram-apps/sdk'
import { useViewport } from '@telegram-apps/sdk-react'
import { selectCharacter, useAppStore } from '@/store.ts'
import { useEffect } from 'react'
import { Flip, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BottomMenu } from '@/components/BottomMenu.tsx'

interface CustomWindow {
  TelegramWebviewProxy: {
    postEvent(p: string, v: string): void
  }
}

declare const window: CustomWindow

export function Root() {
  const vp = useViewport()
  const [miniApp] = initMiniApp()
  const { initDataRaw } = retrieveLaunchParams()
  const character = useAppStore(selectCharacter)
  const getCharacter = useAppStore((s) => s.getCharacter)

  const location = useLocation()

  useEffect(() => {
    if (vp && !vp.isExpanded) vp.expand()
  }, [vp])

  useEffect(() => {
    if (initDataRaw) {
      const ref = new URLSearchParams(location.search).get('ref') || ''
      getCharacter(initDataRaw, ref)
      miniApp.setHeaderColor('#738276')
      miniApp.setBgColor('#738276')
    }
  }, [initDataRaw])

  useEffect(() => {
    if (window.TelegramWebviewProxy?.postEvent) {
      window.TelegramWebviewProxy.postEvent(
        'web_app_setup_swipe_behavior',
        JSON.stringify({ allow_vertical_swipe: false })
      )
    }
  }, [window.TelegramWebviewProxy?.postEvent])

  if (!character) return null

  return (
    <Flex direction={'column'} h={vp?.height || '100%'}>
      <Outlet />
      <BottomMenu />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        closeOnClick
        pauseOnHover={false}
        draggable={false}
        theme="dark"
        transition={Flip}
      />
    </Flex>
  )
}
